import type { FC, MouseEventHandler, ReactNode } from 'react';

import { StyledTextButton } from 'styles/buttons';
import { IS_DEV } from 'utils/constants/common';

import Loader from '../Loader';

export interface TextButtonProps {
  view?: 'primary' | 'secondary';
  type?: 'button' | 'submit' | 'reset';
  dataTestId: string;
  disabled?: boolean;
  isLoading?: boolean;
  icon?: ReactNode;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  width?: string;
  isRelative?: boolean;
  dataTip?: string;
  dataFor?: string;
}

const TextButton: FC<TextButtonProps> = ({
  view = 'primary',
  type = 'button',
  dataTestId,
  disabled,
  isLoading,
  icon,
  children,
  onClick,
  width,
  isRelative = false,
  dataTip,
  dataFor,
}) => {
  return (
    <StyledTextButton
      className={`${view}`}
      type={type}
      isRelative={isRelative}
      disabled={disabled || isLoading}
      onClick={onClick}
      data-testid={IS_DEV && dataTestId}
      width={width}
      data-tip={dataTip}
      data-for={dataFor}
      aria-describedby={dataFor}
    >
      {isLoading ? <Loader size={12} /> : icon}
      {children}
    </StyledTextButton>
  );
};

export default TextButton;
